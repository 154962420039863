import React from "react";
import testimonials from "../../content/landingpage/testimonials.yaml";
import {interpretLineJump} from "../../helpers/interpretLineJumpsInYaml";
import {useLocation} from "@reach/router";

export const Testimonials = () => {

    const location = useLocation();

    const shouldUseCarousel = location.pathname === '/';

    return (
        <div>
            <section className="testimonial ptb-100 gray-light-bg">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-8">
                            <div className="section-heading text-center mb-5">
                                <h1>{testimonials.title}</h1>
                                <p>Plus de témoignages <a href="https://tinyurl.com/y3ovgel3"
                                                          target="_blank"
                                                          rel="noopener noreferrer">ici, sur Google Reviews</a>
                                    {shouldUseCarousel && <><br/>Swiper vers la droite pour les faire défiler.</>}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div
                            className={shouldUseCarousel ? "owl-carousel owl-theme owl-loaded owl-drag client-testimonial dot-indicator" : ''}>
                            {(testimonials.comments || []).map(
                                (data, index) => {
                                    return (
                                        <div className="item" key={index}>
                                            <div className="testimonial-wrap white-bg">
                                                <blockquote className="mb-4 mt-4 lead" dangerouslySetInnerHTML=
                                                    {{__html: interpretLineJump(data.comment.comment)}}>
                                                </blockquote>
                                                <div className="media author-info">
                                                    {[...Array(5)].map((_, i) =>
                                                        <i className="lni lni-star-filled" style={{
                                                            color: (data.comment.star >= i + 1) ?
                                                                'orange' : 'inherit'
                                                        }}/>)}
                                                </div>
                                                <div className="media author-info mt-2">
                                                    {/*<div className="author-img mr-3">
                                                        <img
                                                            src={images[data.comment.image]}
                                                            alt="client"
                                                            className="img-fluid rounded-circle"
                                                        />
                                                    </div>*/}
                                                    <div className="media-body">
                                                        <h5 className="mb-0">{data.comment.name}</h5>
                                                        {/*<span>{data.comment.company}</span>*/}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                }
                            )}
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}